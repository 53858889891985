import React, { useEffect, useState } from "react";
import "./standAloneFileStatus.scss";
import CustomTable from "../../../component/customtable/CustomTable";
import Button from "../../../component/common/Button/button";
import InputField from "../../../component/common/Inputfield/inputfield";
import apiCall from "../../../utils/apiFunction/apiCall";

import { useDispatch, useSelector } from "react-redux";
import SideBar from "../../../component/sideBar";
import { showBar } from "../../../redux/reducer/sideBarStatus";
// import DebitTransactionMergeForm from "./DtMergeForm/DtMergeForm";
import { toast } from "react-toastify";
import {
  commonVariables,
  componentRole,
  selectdownload,
  selectoptions,
  tableID,
  dateFormats
} from "../../../constants/Common/commonConstants";
import { apiConstants, errMessage, toastIds } from "../../../constants/Common/apiConstants";
import SystemXLoader from "../../../component/common/Loader/SystemXLoader";
import CustomTooltip from "../../../component/Tooltip/Tooltip";
import moment from "moment";

const StandAloneFileStatus = () => {
  const [pageNumber, setPageNumber] = useState(0);
  const [count, setCount] = useState(0);
  const [selectMonth, setSelectMonth] = useState(1);
  const [listdata, setListdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [downloadFlag, setDownloadFlag] = useState(false);
  const dispatch = useDispatch();
  let LoginState = useSelector((state) => state.login);
    console.log(LoginState)

  const listApi = (value) => {
    let temp = 0;
    if ((value || pageNumber) > 0) {
      temp = value ? (value - 1) * 10 : (pageNumber - 1) * 10;
    }
    let paramObj = {
      offset: temp || 0,
      limit: apiConstants?.limit,
      month: selectMonth,
      tablename: "standalone_file_log",
      order: [["batch_id", "desc"]],
    };
    setDownloadFlag(true);
    setLoading(true);
    apiCall
      .post(apiConstants?.fetchAllData, paramObj)
      .then(async (res) => {
        if (res.status === 200) {
          let result = res?.data?.response;
          setCount(res?.data?.count || 0);
          result = result.map((item, i) => {
            let newItem = {
              id: temp + i + 1,
              batch_id: item?.batch_id ? item.batch_id : "-",
              file_name: item?.file_name ? item.file_name : "-",
              user_code: item?.user_code ? item.user_code : "-",
              total_count: item?.total_count ? item.total_count : "-",
              success_count: item?.success_count ? item?.success_count : "-",
              reject_count: item?.reject_count ? item?.reject_count : "-",
              file_process_status: item?.file_process_status ? item?.file_process_status : "-",
              upload_date_time: item?.upload_date_time ? item?.upload_date_time : "-",
              download:
                item?.total_count !== "0" ? (
                    <>
                        <button type="button" class="btn px-4 py-1 font-bold" style={{backgroundColor: '#dfdf23'}} disabled={item?.download_s3_key ? false : true} onClick={() => {downloadStandaloneFile(item.download_s3_key)}}>Download</button>
                    </>
                ) : ''
            };

            return newItem;
          });
          if (result?.length > 0) {
            setListdata([...result]);
          }
        }
      })
      .catch((er) => {
        setListdata([]);
        console.log("err", er);
      })
      .finally(() => {
        setDownloadFlag(false);
        setLoading(false);
      });
  };

  const downloadStandaloneFile = (downloadS3Key) => {
    let userData = LoginState?.decodedData;
    let params = {
      user_name: userData?.user_name || "",
      user_code: userData?.user_code || "",
    };
    setDownloadFlag(true);
    let key = downloadS3Key
        apiCall
          .get(`${apiConstants?.downloadPresignedUrl}?key=${key}`)
          .then((response) => {
            if (response.status === 200) {
              const link = document.createElement("a");
              link.href = response?.data;
              link.click();
              toast.success(commonVariables?.FileDownloadSuccess, {
                toastId: toastIds?.FileDownloadSucess,
              });
            }
          })
          .catch((error) => {
            console.log("Error", error);
            return toast.error(errMessage?.InternalServerError, {
              toastId: toastIds?.InternalServerError,
            });
          })
          .finally(() => {
            setDownloadFlag(false);
          });
  };

  const changePage = (value) => {
    listApi(value);
    setPageNumber(value - 1);
  };

  useEffect(() => {
    listApi();
  }, []);
//   useEffect(() => {
//     listApi();
//   }, [selectMonth]);

  return (
    <>
      <div className="d-flex align-items-center justify-content-between mt-4 mb-3 mx-3 flex-wrap gap-2">
        <div className="right-div-header">
          <div className="mt-1">
            <label className="text-nowrap pl-0 common-header">Standalone File Status</label>
          </div>
        </div>
      </div>
      <div className="col dtMergeTable">
        {downloadFlag && (
          <div className="loaderDiv">
            <SystemXLoader />
          </div>
        )}
        <CustomTable
          cols={[
            {
              id: tableID.ID || "",
              title: commonVariables.NO || "",
            },
            {
              id: tableID.BATCH_ID,
              title: commonVariables.BATCH_ID,
            },
            {
              id: tableID.FILE_NAME || "",
              title: commonVariables.FILE_NAME || "",
            },
            {
              id: tableID.TOTAL_COUNT || "",
              title: commonVariables.TOTAL_RECORDS || "",
            },
            {
              id: 'success_count',
              title: commonVariables.Success || "",
            },
            {
              id: 'reject_count',
              title: commonVariables.REJECT || "",
            },
            {
              id: 'upload_date_time',
              title: "Upload Date & Time"
            },
            {
              id: 'file_process_status',
              title: 'File Process Status',
            },
            {
              id: tableID.DOWNLOAD || "",
              // title: commonVariables.DOWNLOAD || "",
              title: "",
            },
          ]}
          data={listdata}
          count={count}
          setPagination={(page) => changePage(page)}
          activePage={pageNumber}
          loading={loading}
        />
      </div>
    </>
  );
};

export default StandAloneFileStatus;
